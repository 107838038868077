const namespaced = true;

import api from "../../../api/sap/entries_sap.js";

const state = {
    entries_sap: [],
    loading: false,
    entry_sap: [],
    entries_sap_base_ref: [],
};
const mutations = {
    MUTATION_SET_ENTRIES_SAP(state, data) {
        state.entries_sap = data;
    },
    SET_LOADING(state, val) {
        state.loading = val;
    },
    CLEAR(state) {
        state.entries_sap = [];
        state.loading = false;
        state.entry_sap = [];
        state.entries_sap_base_ref = [];
    },
    MUTATION_SET_ENTRY_SAP(state, value) {
        state.entry_sap = value;
    },
    MUTATION_SET_ENTRIES_SAP_BASE_REF(state, value) {
        state.entries_sap_base_ref = value;
    },
};

const actions = {
    getEntries(context, data) {
        api.getEntries(data.date1, data.date2)
            .then(function (res) {
                context.commit("MUTATION_SET_ENTRIES_SAP", res.data);
            })
            .catch((e) => {
                context.dispatch(
                    "notifications/add",
                    {
                        color: "error",
                        visibility: true,
                        timeout: 5000,
                        text: e.messages,
                    },
                    { root: true }
                );
            });
    },
    getEntryShow(context, docEntry) {
        context.commit("SET_LOADING", true);
        api.getEntriesShow(docEntry)
            .then(function (res) {
                context.commit("MUTATION_SET_ENTRY_SAP", res.data);
                context.commit("SET_LOADING", false);
            })
            .catch((e) => {
                context.dispatch(
                    "notifications/add",
                    {
                        color: "error",
                        visibility: true,
                        timeout: 5000,
                        text: e.messages,
                    },
                    { root: true }
                );
                context.commit("SET_LOADING", false);
            });
    },
    getEntryShowByRef(context, baseRef) {
        context.commit("SET_LOADING", true);
        api.getEntriesByBaseRef(baseRef)
            .then(function (res) {
                context.commit("MUTATION_SET_ENTRIES_SAP_BASE_REF", res.data);
                context.commit("SET_LOADING", false);
            })
            .catch((e) => {
                context.dispatch(
                    "notifications/add",
                    {
                        color: "error",
                        visibility: true,
                        timeout: 5000,
                        text: e.messages,
                    },
                    { root: true }
                );
                context.commit("SET_LOADING", false);
            });
    },
    async getEntriesBySequence(context, sequence) {
        context.commit("SET_LOADING", true);
        try {
            const res = await api.getEntriesBySequence(sequence);
            context.commit("MUTATION_SET_ENTRIES_SAP", res.data);
            context.commit("SET_LOADING", false);
            return res.data;
        } catch (e) {
            context.commit("SET_LOADING", false);
            context.commit("MUTATION_SET_ENTRIES_SAP", []);
            context.dispatch(
                "notifications/add",
                {
                    color: "error",
                    visibility: true,
                    timeout: 5000,
                    text: e.messages,
                },
                { root: true }
            );
        }
    },
    clear(context) {
        context.commit("CLEAR");
    },
};

const getters = {
    getEntriesByBaseRef(state) {
        return state.entries_sap_base_ref 
    },
};

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
};
