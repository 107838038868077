let prefix = '/api/sap/items';
function load_items(){
    return window.axios.get(prefix+'/index');
}
function load_items_group(ItmsGrpCod, whsCode,page,perPage){
    return window.axios.get(prefix+'/show/groupcode/' + ItmsGrpCod+ '/' + whsCode + '/'+page + '/'+perPage);
    
}
function items(){
    return window.axios.get(prefix+'/historyPrice');
    
}
function load_items_by_PrchseItem(){
    return window.axios.get(prefix+'/indexByPrchseItem');
}
export default {
    load_items,
    load_items_group,
    items,
    load_items_by_PrchseItem
}