let prefix = '/api/cid-dashboard';

function get() {
    return window.axios.get(prefix+'/index');
}
function store(data) {
    return window.axios.post(prefix+'/store', data)
}
function update(id, data) {
    return window.axios.post(prefix+'/'+id+'/update', data);
}
function deleted(id){
    return window.axios.post(prefix+'/'+id+'/delete');
}
export default {
    get,
    store,
    update,
    deleted,
}