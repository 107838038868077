import Vue from 'vue';
import Vuex from 'vuex';

/**
 *  Para mantener el estado de vuex-states de modulos especificos.
 */
import createPersistedState from  'vuex-persistedstate';

Vue.use(Vuex);
// -----------------------  CONFIGURATION MODULES ------------------------
import configuration        from  './modules/configuration.js';
import notifications        from  './modules/notifications.js';
import abilities            from  './modules/abilities.js';
import roles                from  './modules/roles.js';
import files                from  './modules/files.js';
import maps                 from  './modules/maps.js';
import user                 from  './modules/user.js';
import activities           from  './modules/activities.js';
import dates                from './modules/dates.js';
// ------------------------ LOCAL MODULES ---------------------------------
import authentication       from  './modules/authentication.js';
import purchases            from './modules/purchases.js';
import requisitions         from './modules/requisitions.js';
import warehouses           from './modules/warehouses.js';
import requests             from './modules/requests.js';
import costcenter           from './modules/costcenter.js'; 
import userwarehouse        from './modules/userwarehouse.js'; 
import purchases_rules      from './modules/purchasesRules.js'; 
import finance              from './modules/finance.js';  
import batch                from './modules/batch.js';
import policy               from './modules/policy.js';
import location             from './modules/location.js';
import company              from './modules/company.js';
import calendarFile         from './modules/calendarFile.js';
import calendarGroup        from './modules/calendarGroup.js';
import calendarGroupsUser    from './modules/calendarGroupUser.js';
import invoices             from './modules/invoices.js';
import evidences             from './modules/evidences.js';
import activityLog          from  './modules/activityLog.js';
import cid                  from  './modules/cid.js';
import clientPortfolio      from './modules/clientPortfolio.js';
// ------------------------ SAP MODULES ----------------------------------- 
import items_sap            from './modules/sap/items_sap.js';
import groups_sap           from './modules/sap/groups_sap.js';
import projects_sap         from './modules/sap/projects_sap.js';
import warehouses_sap       from './modules/sap/warehouses_sap.js';
import productions_sap      from './modules/sap/productions_sap.js';
import ItemsWarehouse_sap   from './modules/sap/ItemsWarehouse_sap.js';
import goods_issue_sap      from './modules/sap/goods_issue_sap.js';
import invoice_supplier_sap from './modules/sap/invoice/supplier_sap.js'; 
import credit_note_sap      from './modules/sap/credit_note_sap.js'; 
import deliveries_sap       from './modules/sap/deliveries_sap.js';
import entries_sap       from './modules/sap/entries_sap.js';
import payment_outgoing_sap from './modules/sap/payment/outgoing_sap.js'; 
import payment_incoming_sap from './modules/sap/payment/incoming_sap.js'; 
import payment_terms_sap from './modules/sap/payment/terms_sap.js'; 
import goods_receipt_sap from './modules/sap/goods_receipt_sap.js'; 
import report_purchase_sap from './modules/sap/report/report_purchase_sap.js';
import invoice_customer_sap_report from './modules/sap/invoice/customer_sap_report.js';
//----------------------------- DASHBOARD  ----------------------------
import invoice_customer_sap from './modules/sap/invoice/customer_sap.js'; 
import invoice_customer_sap_detail from './modules/sap/invoice/customer_sap_detail.js'; 
import supplier_price_history from './modules/sap/invoice/supplier_price_history.js';
//----------------------------- ----------------------------
import business_partner_sap from './modules/sap/business_partner.js'; 
import purchases_order_sap from './modules/sap/purchases_order_sap.js'; 
// ------------------------ WALMART MODULES ----------------------------------- 
import wm_deliveries from './modules/walmart/deliveries.js';

const dataState = createPersistedState({
  paths: ['configuration','activities','warehouses','costcenter']
  
})

export default new Vuex.Store({
  namespace: true,
  state: {},
  mutations: {},
  actions: {},
  modules: {
    configuration,
    notifications,
    authentication,
    abilities,
    roles,
    files,
    maps,
    user,
    activities,
    dates,
    purchases,
    requisitions,
    warehouses,
    items_sap,
    groups_sap,
    projects_sap,
    warehouses_sap,
    productions_sap,
    business_partner_sap,
    purchases_order_sap,
    requests,
    costcenter,
    ItemsWarehouse_sap,
    goods_issue_sap,
    userwarehouse,
    purchases_rules,
    finance,
    batch,
    policy,
    invoice_supplier_sap,
    invoice_customer_sap,
    credit_note_sap,
    deliveries_sap,
    entries_sap,
    payment_outgoing_sap,
    payment_incoming_sap,
    payment_terms_sap,
    goods_receipt_sap,
    invoice_customer_sap_report,
    location,
    company,
    calendarFile,
    calendarGroup,
    calendarGroupsUser,
    report_purchase_sap,
    invoices,   
    evidences,
    wm_deliveries,
    invoice_customer_sap_detail,
    activityLog,
    cid,
    supplier_price_history,
    clientPortfolio
  },
  plugins: [dataState]
})