const namespaced = true

import api from '../../../api/sap/items_sap';

const state = {
    data: null,
    items_avaible: [],
    items_avaible_flag: false,
    search: '',
    items: [],
    itemsByPrchseItem: [],
    typePrchseItem: null
   
}

const mutations = {
    MUTATION_SET_DATA(state, articles) {
        state.data = articles
    },
    MUTATION_RESET_ITEMS_AVAIBLE(state) {
        state.items_avaible = []

    },
    MUTATION_RESET_ITEMS_AVAIBLE_FLAG(state, value) {
        state.items_avaible_flag = value;
    },
    MUTATION_SEARCH(state, value) {
        state.search = value;
    },
    MUTATION_ITEMS(state, data) {
        state.items = data;
    },
    MUTATION_SET_ITEMS_PRCHSE_ITEM(state,data){
        state.itemsByPrchseItem = data;
    },

 
}
const actions = {
    getArticles(context, all = false) {
        return new Promise((resolve) => {
            api.load_items(all).then(function (res) {
                context.commit('MUTATION_SET_ITEMS_AVAIBLE', res.data);
                resolve(res.data);
            }).catch((e) => {
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });
            })
        })
    },
    getArticlesByPrchseItem(context, all = false) {
        return new Promise((resolve) => {
            context.commit('MUTATION_RESET_ITEMS_AVAIBLE_FLAG', true);
            api.load_items_by_PrchseItem(all).then(function (res) {
                context.commit('MUTATION_SET_ITEMS_PRCHSE_ITEM', res.data);
                context.commit('MUTATION_RESET_ITEMS_AVAIBLE_FLAG', false);
                resolve(res.data);
            }).catch((e) => {
                context.commit('MUTATION_RESET_ITEMS_AVAIBLE_FLAG', false);
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });
            })
        })
    },
    getArticlesFamily(context, data) {
        return new Promise((resolve) => {
            context.commit('MUTATION_RESET_ITEMS_AVAIBLE_FLAG', true);
            api.load_items_group(data.groupcodem, data.whsCode, data.page, 300).then(function (res) {
                context.commit('MUTATION_SET_DATA', res.data);
                context.commit('MUTATION_RESET_ITEMS_AVAIBLE_FLAG', false);
                resolve(res.data);
            }).catch((e) => {
                context.commit('MUTATION_RESET_ITEMS_AVAIBLE_FLAG', false);
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });
                context.commit('MUTATION_RESET_ITEMS_AVAIBLE');
            })
        })
    },
    resetArticles(context) {
        context.commit('MUTATION_RESET_ITEMS_AVAIBLE');
    },
    getArticlesSearch(context, data) {
        context.commit('MUTATION_SET_ITEMS_AVAIBLE', data)
    },
    setSearch(context, value) {
        context.commit('MUTATION_SEARCH', value)
    },
    getTotalItems(context, data) {
        return new Promise((resolve) => {
            context.commit('MUTATION_RESET_ITEMS_AVAIBLE_FLAG', true);
            api.items(data).then(function (res) {
                context.commit('MUTATION_ITEMS', res.data);
                context.commit('MUTATION_RESET_ITEMS_AVAIBLE_FLAG', false);
                resolve(res.data);
            }).catch((e) => {
                context.commit('MUTATION_RESET_ITEMS_AVAIBLE_FLAG', false);
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });
             
            })
        })
    },

}

const getters = {
    getArticlesItem(state) {
        return (state.data != null) ? state.data.items : []
    },
    getNextPage(state) {
        return (state.data != null) ? state.data.nextPage : null
    },
    getAntPage(state) {
        return (state.data != null) ? state.data.antPage : null
    },
    getCountItems(state) {
        return (state.data != null) ? state.data.total : null
    },

    getTotalItems(state) {
        if (state.data == null || state.search == '')
            return 0;
        const lowerSearch = state.search.toString().toLowerCase();
        const searchKeywords = lowerSearch.split(' ');
        let matchCount = 0;
        for (var i = 0; i < state.data.items.length; i++) {
            let existCount = true;
            for (var j = 0; j < searchKeywords.length; j++) {
                if (state.data.items[i].ItemCode.toString().toLowerCase().includes(searchKeywords[j].toString().toLowerCase()) ||
                    state.data.items[i].ItemName.toString().toLowerCase().includes(searchKeywords[j].toString().toLowerCase())) {
                    existCount = true;
                } else {
                    existCount = false;
                    break;
                }
            }
            if (existCount)
                matchCount++
        }
        return matchCount;
    }
}
export default {
    namespaced,
    state,
    getters,
    mutations,
    actions
}