<template>
  <div>
    <v-app-bar app clipped-left fixed>
      <v-row align="center">
        <v-col cols="1" sm="1" md="1" lg="1" xl="1">
          <v-btn block outlined @click.stop="drawerConf = !drawerConf">
            <span class="mdi mdi-menu"></span>
          </v-btn>
        </v-col>
        <v-col cols="9" sm="9" md="9" lg="9" xl="9">
          <span class="subtitle-2 mx-auto font-weight-bold text-truncate">{{ $route.meta.title }} </span>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="2" sm="2" md="2" lg="2" xl="2">
          <quick-access-component></quick-access-component>
        </v-col>
      </v-row>
    </v-app-bar>

    <v-navigation-drawer v-model="drawerConf" temporary fixed width="20%">
      <template v-slot:prepend>
        <v-list-item class="px-4 pt-1">
          <v-list-item-title>Menú</v-list-item-title>
          <v-btn icon @click.stop="drawerConf = !drawerConf">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-list-item>
      </template>
      <v-divider></v-divider>

      <v-list dense nav rounded>
        <v-list-item-group no-action color="accent" @click="changeModule(true)" :value="banner">
          <v-list-item @click="goTo('dashboard')">
            <v-list-item-icon>
              <v-icon>mdi-view-dashboard</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Dashboard</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <v-list-group v-for="module in modulesOrder" :key="module.title" v-show="can(module.permissions)"
          v-model="module.active" :prepend-icon="module.action" no-action color="accent"
          @click="changeModule(module.banner)">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ module.title }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list v-if="module.subsections">
            <v-list-group v-for="sections in module.sections" :key="sections.title" no-action class="pl-15"
              color="accent" :value="banner">
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title v-show="can(sections.permissions)">{{ sections.title }}</v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item v-for="subsections in sections.sections" :key="subsections.title" link class="pl-10"
                @click="goTo(subsections.redirect)">
                <v-list-item-title v-show="can(subsections.permissions)">{{ subsections.title }}</v-list-item-title>
              </v-list-item>
            </v-list-group>
          </v-list>

          <v-list-item v-else v-for="section in module.sections" :key="section.title" link
            v-show="can(section.permissions)" @click="goTo(section.redirect)">
            <v-list-item-content>
              <v-list-item-title v-show="can(section.permissions)">{{ section.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-divider></v-divider>
        <v-subheader class="mt-2">Configuración</v-subheader>
        <v-list-item link>
          <v-list-item-content>
            <v-list-item-icon>
              <v-icon>mdi-brightness-6</v-icon>
            </v-list-item-icon>
          </v-list-item-content>
          <v-list-item-action>
            <v-switch color="accent" v-model="get_is_dark" v-on:change="setDarkMode(get_is_dark)"></v-switch>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <v-list-item link @click="goTo('home')">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ getConfigurationName }} (Inicio)
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link @click="logout">
          <v-list-item-icon>
            <v-icon color="error">mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title :style="{ color: styleErrorColor }">
              Cerrar Sesión
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import default_profile_picture from "../users/images/default_profile_picture.png";
import quickAccessComponent from "./quick-access-component.vue";
import { can } from '../../src/helpers/permissions';
import { mapState, mapActions, mapGetters } from "vuex";
import _ from 'lodash';
export default {
  data: () => ({
    banner: true,
    drawer: true,
    group: null,
    drawerConf: false,
    modules: [
      {
        title: 'Usuarios',
        action: 'mdi-account-group',
        active: false,
        permissions: [
          'get-users', 'store-users', 'update-users', 'delete-users',
          'get-abilities', 'store-abilities', 'update-abilities', 'delete-abilities',
          'get-roles', 'store-roles', 'update-abilities', 'delete-abilities',
        ],
        subsections: false,
        sections: [
          {
            title: 'Gestión de Usuarios',
            redirect: 'users',
            permissions: [
              'get-users', 'store-users', 'update-users', 'delete-users',
              'get-abilities', 'store-abilities', 'update-abilities', 'delete-abilities',
              'get-roles', 'store-roles', 'update-abilities', 'delete-abilities',
            ],
          },
        ],
        banner: false,
      },
      {
        title: 'Pagos',
        action: 'mdi-credit-card',
        active: false,
        permissions: ['auth-batch-payment', 'review-batch-payment', 'order-batch-payment'],
        subsections: false,
        sections: [
          {
            title: 'Lotes de pago',
            redirect: 'payments',
            permissions: ['auth-batch-payment', 'review-batch-payment', 'order-batch-payment'],
          },
          {
            title: 'Historico de facturas',
            redirect: 'payments/historyInvoice',
            permissions: ['view-history-invoice'],
          },
        ],
        banner: false,
      },
      {
        title: 'Compras',
        action: 'mdi-purse',
        active: false,
        permissions: [
          'requisitions', 'report-requisition', 'auth-requisition', 'get_requisition',
        ],
        subsections: false,
        sections: [
          {
            title: 'Histórico de precios',
            active: false,
            redirect: 'purchases/historyprice',
            permissions: [
              'history-price'
            ],
          },
          {
            title: 'Requisiciones de compra',
            redirect: 'purchases/requisitions',
            active: false,
            permissions: [
              'get_requisition', 'requisitions'
            ],

          },
          {
            title: 'Reporte de requisiciones',
            redirect: 'purchases/requisitionsReports',
            active: false,
            permissions: [
              'report-requisition'
            ],

          },
          {
            title: 'Reglas de compra',
            active: false,
            redirect: 'purchases/purchasesrules',
            permissions: [],
          },
          {
            title: 'Reporte de compras',
            active: false,
            redirect: 'purchases/purchasesReports',
            permissions: [
              'get-purchases-report'
            ],
          },
        ],
        banner: false,
      },
      // {
      //   title: 'Almacenes',
      //   action: 'mdi-warehouse',
      //   active: false,
      //   permissions: [
      //     'store-vouncher', 'get-vouncher', 'delete-vouncher', 'update-vouncher'
      //   ],
      //   subsections: true,
      //   sections: [
      //     {
      //       title: 'Salidas de mercancia',
      //       active: false,
      //       redirect: '',
      //       permissions: [
      //         'store-vouncher', 'get-vouncher', 'delete-vouncher', 'update-vouncher'
      //       ],
      //       sections: [
      //         {
      //           title: 'Solicitud de vale de salida',
      //           redirect: 'warehouse/exit/request',
      //           permissions: [
      //             'store-vouncher', 'get-vouncher', 'delete-vouncher', 'update-vouncher'
      //           ],

      //         },
      //       ],
      //       banner: false,
      //     },
      //     {
      //       title: 'Entrada de mercancia',
      //       active: false,
      //       redirect: '',
      //       permissions: [
      //         'store-vouncher', 'get-vouncher', 'delete-vouncher', 'update-vouncher'
      //       ],
      //       sections: [{
      //         title: 'Solicitud entrada de mercancia',
      //         redirect: 'warehouse/entry',
      //         permissions: [
      //           'store-vouncher', 'get-vouncher', 'delete-vouncher', 'update-vouncher'
      //         ],
      //       }]
      //     }
      //   ],
      // },
      {
        title: 'Finanzas',
        action: 'mdi-finance',
        active: false,
        permissions: ['create-batch-payment', 'taxes-manager'],
        subsections: false,
        sections: [
          {
            title: 'Pago de Proveedores',
            redirect: 'finances/suppliers/payments',
            permissions: ['create-batch-payment'],
          },
          {
            title: 'Devolución de IVA',
            redirect: 'finances/taxes/manager',
            permissions: ['taxes-manager'],
          }
        ],
      },
      {
        title: 'Producción',
        action: 'mdi-account-hard-hat',
        active: false,
        permissions: ['view-productions-dashboard'],
        subsections: false,
        sections: [
          {
            title: 'Dashboard',
            redirect: 'productions/dashboard',
            permissions: ['view-productions-dashboard'],
          },
        ],
      },
      {
        title: 'Gerencia',
        action: 'mdi-account-tie',
        active: false,
        permissions: ['view-manager-sales', 'view-manager-productions'],
        subsections: false,
        sections: [
          {
            title: 'Ventas',
            redirect: 'manager/sales',
            permissions: ['view-manager-sales'],
          },
          {
            title: 'Producciones',
            redirect: 'manager/productions',
            permissions: ['view-manager-productions'],
          },
        ],
      },
      // {
      //   title: 'Recursos Humanos',
      //   action: 'mdi-account-cash',
      //   active: false,
      //   permissions: [],
      //   subsections: false,
      //   sections: [
      //     {
      //       title: 'Nóminas',
      //       redirect: 'rh/payroll',
      //       permissions: [],
      //     },
      //   ],
      // },
      {
        title: 'Ventas',
        action: 'mdi-cash',
        active: false,
        permissions: ['client-portfolio'],
        subsections: false,
        sections: [
          {
            title: 'Cartera de Cliente',
            redirect: 'sales/clientPortfolio',
            permissions: ['client-portfolio'],
          },
        ],
      },
      // {
      //   title: 'Soporte',
      //   action: 'mdi-tools',
      //   active: false,
      //   permissions: [],
      //   subsections: true,
      //   sections: [
      //     {
      //       title: 'Compras',
      //       redirect: '',
      //       permissions: [],
      //       sections: [
      //         {
      //           title: 'Lotes de Pago',
      //           redirect: 'support/lots',
      //           permissions: [],
      //         },
      //       ],
      //     },
      //   ],
      // },
      {
        title: 'Configuración',
        action: 'mdi-cog',
        active: false,
        permissions: ['settings-list'],
        subsections: false,
        sections: [
          {
            title: 'Generales',
            redirect: 'settings',
            permissions: ['settings-list'],
          },
        ],
      },
      {
        title: 'Calendario de documentos',
        action: 'mdi-calendar',
        active: false,
        permissions: ['root-calendar', 'responsible-group-calendar', 'responsible-document'],
        subsections: false,
        sections: [
          {
            title: 'Calendario',
            redirect: 'calendarFiles',
            permissions: ['root-calendar', 'responsible-group-calendar', 'responsible-document'],
          },
        ],
      },
      {
        title: 'Reportes',
        action: 'mdi-chart-sankey',
        active: false,
        permissions: ['report_purchases', 'report_invoices', 'report_inventories', 'report_productions', 'report_sales'],
        subsections: false,
        sections: [
            {
                title: 'Compras',
                redirect: 'report/purchases',
                permissions: ['report_purchases'],
            },
            // {
            //     title: 'Facturación',
            //     redirect: 'report/invoices',
            //     permissions: ['report_invoices'],
            // },
            // {
            //     title: 'Inventarios',
            //     redirect: 'report/inventories',
            //     permissions: ['report_inventories'],
            // },
            // {
            //     title: 'Producciones',
            //     redirect: 'report/productions',
            //     permissions: ['report_productions'],
            // },
            // {
            //     title: 'Ventas',
            //     redirect: 'report/sales',
            //     permissions: ['report_sales'],
            // },
        ],
      },
      {
        title: 'Evidencias',
        action: 'mdi-file',
        active: false,
        permissions: ['upload_file', 'delete_file'],
        subsections: false,
        sections: [
          {
            title: 'Anexar evidencia',
            redirect: 'evidences',
            permissions: ['upload_file', 'delete_file'],
          },
        ],
      },
      {
        title: 'Bascula',
        action: 'mdi-weight-pound',
        active: false,
        permissions: ['check-ticket-information'],
        subsections: false,
        sections: [
          {
            title: 'Comprobación de tickets',
            redirect: 'weightmachine/tickets',
            permissions: ['check-ticket-information'],
          },
        ],
      },
    ],
  }),
  components: {
    quickAccessComponent
  },
  computed: {
    ...mapState('configuration', ['themeDark']),
    ...mapState("authentication", ["userAuth"]),
    ...mapGetters('configuration', ['getAbilitiesUser', 'getConfigurationName']),
    modulesOrder() {
      return _.orderBy(this.modules, 'title', 'asc');
    },
    logged() {
      return this.$route.name !== "home";
    },
    sideMenu() {
      return this.$vuetify.breakpoint.width <= 1264 && this.logged;
    },
    get_is_dark: {
      get: function () {
        return this.themeDark;
      },
      set: function (newValue) {
        return newValue;
      },
    },
    styleErrorColor() {
      return this.$vuetify.theme.dark ? this.$vuetify.theme.themes.dark.error : this.$vuetify.theme.themes.light.error;
    }
  },
  methods: {
    ...mapActions("configuration", ["setDarkMode"]),
    can(permissions) {
      return can(this.getAbilitiesUser, permissions);
    },
    logout() {
      this.$router.push({ name: "logout" });
    },
    goTo(name) {
      this.banner = false
      this.$router.push({ name: name }).catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    },
    showhere(route) {
      return this.$route.fullPath != route ? true : false;
    },
    checkImage(image) {
      if (
        image == "default_user_image.jpg" ||
        image == "default_profile_picture.png" ||
        image == null
      ) {
        return default_profile_picture;
      } else {
        return image;
      }
    },
    changeModule(banner) {
      this.banner = banner
    }

  },
  watch: {
    get_is_dark: function (val) {
      this.$vuetify.theme.dark = val;
    },
  },
  mounted() {
    this.$vuetify.theme.dark = this.get_is_dark;
  },
};
</script>

<style>
.router-link-exact-active {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  color: #1867c0 !important;
  caret-color: #1867c0 !important;
  font-style: italic;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);

}
</style>
