const namespaced = true
import api from '../../../../api/sap/invoice/customer_sap.js';
import _ from 'lodash';
import { downloadFile } from '../../../../src/helpers/util.js'

const state = {
    loading: false,
    invoices_customer_sap: [],
    invoice_customer: null,
    invoices_customer_sap_dashboard: [],
}

const mutations = {
    SET_LOADING(state, val) {
        state.loading = val;
    },
    MUTATION_SET_INVOICE_CUSTOMER_SAP(state, data) {
        state.invoices_customer_sap = data;
    },
    MUTATION_SET_INVOICE_CUSTOMER(state, data) {
        state.invoice_customer = data;
    },
    MUTATION_SET_INVOICE_CUSTOMER_SAP_DASHBOARD(state, data) {
        state.invoices_customer_sap_dashboard = data;
    },

}
const actions = {
    getInvoicesCustomer(context, data) {
        api.getInvoicesCustomer(data.date1, data.date2).then(function (res) {
            context.commit('MUTATION_SET_INVOICE_CUSTOMER_SAP', res.data);
        }).catch((e) => {
            context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });
        })
    },
    getInvoice(context, data) {
        return new Promise((resolve) => {
            api.getInvoice(data).then(function (res) {
                context.commit('MUTATION_SET_INVOICE_CUSTOMER', res.data);
                resolve(res.data);
            }).catch((e) => {
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });
            })
        })

    },
    getSales(context, data) {
        return new Promise((resolve) => {
            context.commit('SET_LOADING', true);
            api.getSales(data.date1, data.date2).then(function (res) {
                context.commit('MUTATION_SET_INVOICE_CUSTOMER_SAP_DASHBOARD', res.data);
                context.commit('SET_LOADING', false);
                resolve(res.data);
            }).catch((e) => {
                context.commit('SET_LOADING', false);
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });
            })
        })

    },
    getSalesExport(context, data) {
        return new Promise((resolve) => {
            context.commit('SET_LOADING', true);
            api.getSalesExport(data).then(function (res) {
                downloadFile(res.data, 'analisis de ventas.xlsx');
                context.commit('SET_LOADING', false);
                resolve(res.data);
            }).catch((e) => {
                context.commit('SET_LOADING', false);
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });
            })
        })

    },
}

const getters = {
    getDataTable(state) {
        const bannerInvoice = _.has(state.invoices_customer_sap_dashboard, 'invoices');
        const bannerInvoiceCancelations = _.has(state.invoices_customer_sap_dashboard, 'invoiceCancelations');
        if (bannerInvoice && bannerInvoiceCancelations)
            return _.concat(state.invoices_customer_sap_dashboard.invoices, state.invoices_customer_sap_dashboard.invoiceCancelations);
        else
            return [];
    },
    getDataTableExport(state, getter) {
        let transformData = _.map(getter.getDataTable, item => [
            item.CardName,
            item.DocDate,
            item.DocNum,
            item.ItmsGrpCod,
            item.ItmsGrpNam,
            item.DocTotal,
            item.TipoDocumento,
            item.Weight,
            item.WTSum,
            item.VatSum,
            item.CANCELED,
        ]);
        return transformData;
    },
    getTotalArena(state) {
        let sumInvoice = _.sumBy(_.filter(state.invoices_customer_sap_dashboard.invoices, function (o) { return o.ItmsGrpCod == '108'; }), 'Weight');
        let sumInvoiceCancelations = _.sumBy(_.filter(state.invoices_customer_sap_dashboard.invoiceCancelations, function (o) { return o.ItmsGrpCod == '108'; }), 'Weight');
        return (sumInvoice - sumInvoiceCancelations) / 1000;
    },
    getTotalMontoArena(state) {
        let sumInvoice = _.sumBy(_.filter(state.invoices_customer_sap_dashboard.invoices, function (o) { return o.ItmsGrpCod == '108'; }), 'DocTotal');
        let sumInvoiceTax = _.sumBy(_.filter(state.invoices_customer_sap_dashboard.invoices, function (o) { return o.ItmsGrpCod == '108'; }), 'VatSum');
        let sumInvoiceCancelations = _.sumBy(_.filter(state.invoices_customer_sap_dashboard.invoiceCancelations, function (o) { return o.ItmsGrpCod == '108'; }), 'DocTotal');
        let sumInvoiceCancelationsTax = _.sumBy(_.filter(state.invoices_customer_sap_dashboard.invoiceCancelations, function (o) { return o.ItmsGrpCod == '108'; }), 'VatSum');

        return ((sumInvoice - sumInvoiceTax) - (sumInvoiceCancelations - sumInvoiceCancelationsTax));
    },

    getTotalTonFertilizante(state) {
        let sumInvoice = _.sumBy(_.filter(state.invoices_customer_sap_dashboard.invoices, function (o) { return o.ItmsGrpCod == '102'; }), 'Weight');
        let sumInvoiceCancelations = _.sumBy(_.filter(state.invoices_customer_sap_dashboard.invoiceCancelations, function (o) { return o.ItmsGrpCod == '102'; }), 'Weight');
        return (sumInvoice - sumInvoiceCancelations) / 1000;
    },
    getTotalMontoFertilizante(state) {
        let sumInvoice = _.sumBy(_.filter(state.invoices_customer_sap_dashboard.invoices, function (o) { return o.ItmsGrpCod == '102'; }), 'DocTotal');
        let sumInvoiceTax = _.sumBy(_.filter(state.invoices_customer_sap_dashboard.invoices, function (o) { return o.ItmsGrpCod == '102'; }), 'VatSum');
        let sumInvoiceCancelations = _.sumBy(_.filter(state.invoices_customer_sap_dashboard.invoiceCancelations, function (o) { return o.ItmsGrpCod == '102'; }), 'DocTotal');
        let sumInvoiceCancelationsTax = _.sumBy(_.filter(state.invoices_customer_sap_dashboard.invoiceCancelations, function (o) { return o.ItmsGrpCod == '102'; }), 'VatSum');

        return ((sumInvoice - sumInvoiceTax) - (sumInvoiceCancelations - sumInvoiceCancelationsTax));
    },

    getTotalTonFosfatos(state) {
        let sumInvoice = _.sumBy(_.filter(state.invoices_customer_sap_dashboard.invoices, function (o) { return o.ItmsGrpCod == '103'; }), 'Weight');
        let sumInvoiceCancelations = _.sumBy(_.filter(state.invoices_customer_sap_dashboard.invoiceCancelations, function (o) { return o.ItmsGrpCod == '103'; }), 'Weight');
        return (sumInvoice - sumInvoiceCancelations) / 1000;
    },
    getTotalMontoFosfatos(state) {
        let sumInvoice = _.sumBy(_.filter(state.invoices_customer_sap_dashboard.invoices, function (o) { return o.ItmsGrpCod == '103'; }), 'DocTotal');
        let sumInvoiceTax = _.sumBy(_.filter(state.invoices_customer_sap_dashboard.invoices, function (o) { return o.ItmsGrpCod == '103'; }), 'VatSum');
        let sumInvoiceCancelations = _.sumBy(_.filter(state.invoices_customer_sap_dashboard.invoiceCancelations, function (o) { return o.ItmsGrpCod == '103'; }), 'DocTotal');
        let sumInvoiceCancelationsTax = _.sumBy(_.filter(state.invoices_customer_sap_dashboard.invoiceCancelations, function (o) { return o.ItmsGrpCod == '103'; }), 'VatSum');

        return ((sumInvoice - sumInvoiceTax) - (sumInvoiceCancelations - sumInvoiceCancelationsTax));
    },

    getTotalTonMinerales(state) {
        let sumInvoice = _.sumBy(_.filter(state.invoices_customer_sap_dashboard.invoices, function (o) { return o.ItmsGrpCod == '109'; }), 'Weight');
        let sumInvoiceCancelations = _.sumBy(_.filter(state.invoices_customer_sap_dashboard.invoiceCancelations, function (o) { return o.ItmsGrpCod == '109'; }), 'Weight');
        
        let sumInvoicesSM = _.sumBy(_.filter(state.invoices_customer_sap_dashboard.invoices, function (o) { return o.ItmsGrpCod == '106'; }), 'Weight');
        let sumInvoiceCancelationsSM = _.sumBy(_.filter(state.invoices_customer_sap_dashboard.invoiceCancelations, function (o) { return o.ItmsGrpCod == '106'; }), 'Weight');

        return ((sumInvoice - sumInvoiceCancelations) + (sumInvoicesSM - sumInvoiceCancelationsSM)) / 1000;
    },
    getTotalMontoMinerales(state) {

        let sumInvoice = _.sumBy(_.filter(state.invoices_customer_sap_dashboard.invoices, function (o) { return o.ItmsGrpCod == '109'; }), 'DocTotal');
        let sumInvoiceTax = _.sumBy(_.filter(state.invoices_customer_sap_dashboard.invoices, function (o) { return o.ItmsGrpCod == '109'; }), 'VatSum');
        let sumInvoiceCancelations = _.sumBy(_.filter(state.invoices_customer_sap_dashboard.invoiceCancelations, function (o) { return o.ItmsGrpCod == '109'; }), 'DocTotal');
        let sumInvoiceCancelationsTax = _.sumBy(_.filter(state.invoices_customer_sap_dashboard.invoiceCancelations, function (o) { return o.ItmsGrpCod == '109'; }), 'VatSum');

        let sumInvoiceSM = _.sumBy(_.filter(state.invoices_customer_sap_dashboard.invoices, function (o) { return o.ItmsGrpCod == '106'; }), 'DocTotal');
        let sumInvoiceTaxSM = _.sumBy(_.filter(state.invoices_customer_sap_dashboard.invoices, function (o) { return o.ItmsGrpCod == '106'; }), 'VatSum');
        let sumInvoiceCancelationsSM = _.sumBy(_.filter(state.invoices_customer_sap_dashboard.invoiceCancelations, function (o) { return o.ItmsGrpCod == '106'; }), 'DocTotal');
        let sumInvoiceCancelationsTaxSM = _.sumBy(_.filter(state.invoices_customer_sap_dashboard.invoiceCancelations, function (o) { return o.ItmsGrpCod == '106'; }), 'VatSum');

        return ((sumInvoice - sumInvoiceTax) - (sumInvoiceCancelations - sumInvoiceCancelationsTax)) + ((sumInvoiceSM - sumInvoiceTaxSM) - (sumInvoiceCancelationsSM - sumInvoiceCancelationsTaxSM));
    },

    getTotalTonPremezclas(state) {
        let sumInvoice = _.sumBy(_.filter(state.invoices_customer_sap_dashboard.invoices, function (o) { return o.ItmsGrpCod == '104'; }), 'Weight');
        let sumInvoiceCancelations = _.sumBy(_.filter(state.invoices_customer_sap_dashboard.invoiceCancelations, function (o) { return o.ItmsGrpCod == '104'; }), 'Weight');
        return (sumInvoice - sumInvoiceCancelations) / 1000;
    },
    getTotalMontoPremezclas(state) {
        let sumInvoice = _.sumBy(_.filter(state.invoices_customer_sap_dashboard.invoices, function (o) { return o.ItmsGrpCod == '104'; }), 'DocTotal');
        let sumInvoiceTax = _.sumBy(_.filter(state.invoices_customer_sap_dashboard.invoices, function (o) { return o.ItmsGrpCod == '104'; }), 'VatSum');
        let sumInvoiceCancelations = _.sumBy(_.filter(state.invoices_customer_sap_dashboard.invoiceCancelations, function (o) { return o.ItmsGrpCod == '104'; }), 'DocTotal');
        let sumInvoiceCancelationsTax = _.sumBy(_.filter(state.invoices_customer_sap_dashboard.invoiceCancelations, function (o) { return o.ItmsGrpCod == '104'; }), 'VatSum');

        return ((sumInvoice - sumInvoiceTax) - (sumInvoiceCancelations - sumInvoiceCancelationsTax));
    }

}
export default {
    namespaced,
    state,
    getters,
    mutations,
    actions
}