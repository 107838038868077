const namespaced = true

import api from '../../../api/sap/deliveries_sap.js';

const state = {
    deliveries_sap: [],
}
const mutations = {
    MUTATION_SET_DELIVERIES_SAP(state, data){
         state.deliveries_sap = data;
    }
}
const actions = {
    getDeliveries(context, data) {
        api.getDeliveries(data.date1, data.date2).then(function (res) {
            context.commit('MUTATION_SET_DELIVERIES_SAP', res.data);
        }).catch((e) => {
            context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': e.messages}, {root: true });
        })
    },
}

const getters = {}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}